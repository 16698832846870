import { Component, Inject, Input, OnInit, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentService } from "./payment.service";
import { IPaymentInfo, ICartItem, IBillingInfo, IRegion } from "./payment.model";
import paymentTemplate from './payment.component.html';

interface IPaymentComponent {
}

@Component({
    selector: 'payment-component',
    template: paymentTemplate
})
export class PaymentComponent implements IPaymentComponent, OnInit {
    @ViewChild('nonusModal') nonusModal: HTMLDivElement;
    billing: IBillingInfo = {
        FirstName: '',
        LastName: '',
        Country: 'us',
        Address1: '',
        Address2: '',
        City: '',
        Region: null,
        PostalCode: '',
        Phone: ''
    };
    regions: IRegion[];
    billingInfoIsValid: boolean = false;

    cardholder: string = "";
    cardtype: string = "VI";
        /*[AE] => American Express
        [VI] => Visa
        [MC] => MasterCard
        [DI] => Discover
        [JCB] => JCB
        [SM] => Switch/Maestro
        [DN] => Diners
        [SO] => Solo
        [MI] => Maestro International
        [MD] => Maestro Domestic
        [OT] => Other*/
    ccn: string = "";
    maskedCcn: string = "";
    exp: string = "";
    maskedExp: string = "";
    cvc: string = "";
    zip: string = "";
    ccInfoIsValid: boolean = false;

    cartId: number = 0;
    cartLoaded: boolean = false;
    checkoutInProgress: boolean = false;
    showError: boolean = false;
    supportNumber: string = "1-800-650-6787";
    errorText: string = "Something went wrong. Please double-check your payment information to ensure it is correct and try again. If the problem persists, please contact customer support at " + this.supportNumber + ".";
    paymentComplete: boolean = false;
    adaPending: boolean = false;
    billPending: boolean = false;
    recertDone: boolean = false;
    isUnavailable: boolean = false;

    remoteLink: string = "";
    inPersonLink: string = "";

    @Input() examcode: string;
    @Input() candidateid: string;
    product: ICartItem = {
        Id: 0,
        Sku: '',
        Name: '',
        Price: 0
    };

    constructor(@Inject(PaymentService) private paymentService: PaymentService, @Inject(NgbModal) private modalService: NgbModal) {
    }

    ngOnInit() {
        this.regions = this.paymentService.getUSRegions();
        this.loadProduct();
    }

    @HostListener('document:visibilitychange', ['$event']) OnVisibilityChange() {
        //check the page visibility
        if (document.visibilityState === "hidden") {
            //abandon cart detected; remove unpaid cart items
            this.clearUnpaidCart();
        }
        else {
            if (this.paymentComplete == false) {
                //restore cart
                if (this.product.Id > 0) {
                    this.loadCart();
                }
                else {
                    this.loadProduct();
                }
            }
        }
    }

    clearUnpaidCart() {
        //check whether the items in the cart has been purchased
        if (this.hasUnpaidCart()) {
            this.paymentService.emptyCart(this.cartId);
        }
    }

    hasUnpaidCart(): boolean {
        return this.paymentComplete == false && this.cartLoaded && this.cartId > 0;
    }

    loadProduct() {
        this.paymentService.productDetails(this.examcode, this.candidateid).then(result => {
            this.product = result;
            this.loadCart();
        }).catch(error => {
            console.error('product details error: ', error);
            this.isUnavailable = true;
            this.showError = true;
            this.errorText = `Online payment is temporarily unavailable. Please contact customer support at ${this.supportNumber} to complete your order.`;
        });
    }

    loadCart() {
        this.paymentService.createCart(this.candidateid, this.examcode, this.product.Sku).then(result => {
            //verify that result contains a valid cart ID
            if (result > 0) {
                this.cartLoaded = true;
                this.cartId = result;
            }
            else {
                console.error('invalid cart ID');
                this.isUnavailable = true;
                this.showError = true;
                this.errorText = `Unable to create the shopping cart. Please contact customer support at ${this.supportNumber} to complete your order.`;
            }
        }).catch(error => {
            console.error('create cart error: ', error);
            this.isUnavailable = true;
            this.showError = true;
            this.errorText = `Unable to create the shopping cart. Please contact customer support at ${this.supportNumber} to complete your order.`;
        });
    }

    submitOrder() {
        console.log("submitting order...");
        let expM:number = 0;
        let expY:number = 0;
        if (this.exp.length != 4) {
            console.log("length is " + this.exp.length);
            return;
        } else {
            expM = parseInt(this.exp.substring(0, 2), 10);
            expY = parseInt(this.exp.substring(2, 4), 10);
        }
        let cvcInt:number = parseInt(this.cvc, 10);
        let zipInt:number = parseInt(this.zip, 10);

        let paymentInfo: IPaymentInfo = { CartId: this.cartId, CardholderName: this.cardholder, CreditCardType: this.cardtype, CreditCardNumber: this.ccn, CreditCardExpMonth: expM, CreditCardExpYear: expY, CreditCardCVC: this.cvc, CreditCardZip: this.zip };

        this.checkoutInProgress = true;
        this.showError = false;
        this.paymentService.submitBilling(this.candidateid, this.cartId, this.billing).then(billResult => 
        {
            if (billResult == true) {
                this.paymentService.submitOrder(paymentInfo).then(orderId => {
                    if (orderId > 0) {
                        this.paymentService.confirmPayment(this.candidateid, this.examcode).then(payResult => {
                            //do nothing, we don't really care if this step fails
                        });
                        //stick this next bit in an if to determine if this is a recert, which has no exam
                        this.paymentService.isRecert(this.candidateid, this.examcode).then(isrecert => {
                            if (isrecert) {
                                this.paymentService.recertify(this.candidateid, this.examcode).then(recertResult => {
                                    this.checkoutInProgress = false;
                                    this.paymentComplete = true; //hide page and show thank-you
                                    this.recertDone = true;
                                    this.paymentService.sendConfirmationEmail(this.candidateid, this.examcode, "recert");
                                }).catch(error => {
                                    console.log('recertify error', error);
                                    this.checkoutInProgress = true;//don't let them resubmit
                                    this.showError = true;
                                    this.errorText = `Something went wrong. Your payment was successfully processed, but our system encountered an error processing your recertification. Please contact customer support at ${this.supportNumber} to update your recertification.`;
                                });
                            }
                            else {
                                this.paymentService.isAda(this.candidateid, this.examcode).then(adaResult => {
                                    if (adaResult) {
                                        this.paymentService.deferAda(this.candidateid, this.examcode).then(adaSetResult => {
                                            this.adaPending = true;
                                            this.checkoutInProgress = false;
                                            this.paymentComplete = true;
                                            this.paymentService.sendConfirmationEmail(this.candidateid, this.examcode, "ada");
                                        }).catch(error => {
                                            console.log('defer ada error', error);
                                            this.checkoutInProgress = true;//don't let them resubmit
                                            this.showError = true;
                                            this.errorText = `Something went wrong. Your payment was successfully processed, but our system encountered an error while processing your account. Please contact customer support at ${this.supportNumber}.`;
                                        });
                                    }
                                    else {
                                        this.paymentService.submitXelig(this.candidateid, this.examcode, true).then(xresult => {
                                            this.paymentService.getPrometricScheduleLink(this.candidateid, this.examcode).then(linkResult => {
                                                this.remoteLink = linkResult.replace("%%TEMPLATEREPLACE%%", "proctorsch");
                                                this.inPersonLink = linkResult.replace("%%TEMPLATEREPLACE%%", "schd");
                                                this.checkoutInProgress = false;
                                                this.paymentComplete = true; //hide page and show thank-you
                                                this.paymentService.setPending(this.candidateid, this.examcode);
                                                //let emailCopy = "insert email copy here. links are " + this.remoteLink + " and " + this.inPersonLink + " .";
                                                this.paymentService.sendConfirmationEmail(this.candidateid, this.examcode, "normal");
                                            }).catch(error => {
                                                console.log('scheduling email error', error);
                                                this.checkoutInProgress = true;//don't let them resubmit
                                                this.showError = true;
                                                this.errorText = `Something went wrong. Your payment was successfully processed, and you are eligible to schedule an exam with Prometric, but the system failed to generate links to your scheduling page. Please contact customer support at ${this.supportNumber} to schedule your exam.`;
                                            });
                                        }).catch(error => {
                                            console.log('prometric submission error', error);
                                            this.checkoutInProgress = true;//don't let them resubmit
                                            this.showError = true;
                                            this.errorText = `Something went wrong. Your payment was successfully processed, but our system failed to register your eligibility. Please contact customer support at ${this.supportNumber} to schedule your exam.`;
                                        });
                                    }
                                }).catch(error => {
                                    console.log('error during ada check', error);
                                    this.checkoutInProgress = true; //don't let them resubmit
                                    this.showError = true;
                                    this.errorText = `Something went wrong. Your payment was successfully processed, but our system failed while checking your ADA status. Please contact customer support at ${this.supportNumber} to schedule your exam.`;
                                });
                            }
                        }).catch(error => {
                            console.log('error during recert check', error);
                            this.checkoutInProgress = true;//don't let them resubmit
                            this.showError = true;
                            this.errorText = `Something went wrong. Your payment was successfully processed, but our system failed to update your certification. Please contact customer support at ${this.supportNumber}.`;
                        });
                    }
                    else {
                        console.log('invalid order id detected');
                        this.checkoutInProgress = false;
                        this.showError = true;
                        this.errorText = `Unable to process your payment. Please double-check your payment information to ensure it is correct and try again. If the problem persists, please contact customer support at ${this.supportNumber}.`;
                    }
                }).catch(error => {
                    console.log('submit order error', error);
                    this.checkoutInProgress = false;
                    this.showError = true;
                    this.errorText = `Something went wrong. Please double-check your payment information to ensure it is correct and try again. If the problem persists, please contact customer support at ${this.supportNumber}.`;
                });
            }
            else {
                console.log('submit billing failed');
                this.checkoutInProgress = false;
                this.showError = true;
                this.errorText = `Something went wrong. Your billing information was not received. Please contact customer support at ${this.supportNumber} to complete your order.`;
            }
        }).catch(error => {
            console.log('submit billing error', error);
            this.checkoutInProgress = false;
            this.showError = true;
            this.errorText = `Something went wrong. Your billing information was not received. Please contact customer support at ${this.supportNumber} to complete your order.`;
        });
        
    }

    applyCoupon(couponCode: string) {
        this.paymentService.applyCoupon(this.cartId, couponCode).then(isApplied => {
            if (!isApplied) {
                this.showError = true;
                this.errorText = `Unable to apply coupon code ${couponCode} to your cart. Please contact customer support at ${this.supportNumber} to complete your order.`;
            }
        });
    }

    onCCNEntry(event: KeyboardEvent) {
        let strippedCcn = this.maskedCcn.replace(/\D/g, '').substring(0,16);//remove all non-numeric input and clip to 16 characters
        this.ccn = strippedCcn;
        this.maskedCcn = this.addSpacesToCCN(strippedCcn);
        this.onCCInfoEntry(null);
    }
    addSpacesToCCN(num:string) {
        let spaced = "";
        for (let i = 0; i < num.length; i++) {
            let digit = num.substring(i, i + 1);
            if (i != 0 && i % 4 == 0)
                spaced += " ";
            spaced += digit;
        }
        return spaced;
    }

    onExpEntry(event: KeyboardEvent) {
        let strippedExp = this.maskedExp.replace(/\D/g, '').substring(0, 4)//remove all non-numeric input and clip to 4 characters
        this.exp = strippedExp;
        this.maskedExp = this.formatExpDate(strippedExp);
        this.onCCInfoEntry(null);
    }
    formatExpDate(num: string) {
        if (num.length <= 2) {
            return num;
        }
        else {
            return num.substring(0, 2) + "/" + num.substring(2, 4);
        }
    }

    onBillingInfoEntry(event: KeyboardEvent) {
        //validate billing info
        this.billingInfoIsValid = this.paymentService.isBillingValid(this.billing);
        if (this.billing.Country == 'nonus') {
            let modalRef = this.modalService.open(this.nonusModal, {});
        }
    }
    onStateEntry(event: any) {
        this.onBillingInfoEntry(null);
    }
    onCCInfoEntry(event: any) {
        this.ccInfoIsValid = this.cardholder != "" && this.isCCNValid() &&
            this.exp.length == 4 && this.isNumerical(this.exp) && this.isCVCValid() &&
            this.zip.length == 5 && this.isNumerical(this.zip) && this.cardtype != "";
    }
    isNumerical(str:string) {
        let reg = /^\d+$/;
        return reg.test(str);
    }
    isCCNValid() {
        switch (this.cardtype) {
            case "AE":
                return (this.ccn.length == 15 || this.ccn.length == 16) && this.isNumerical(this.ccn);
            default:
                return this.ccn.length == 16 && this.isNumerical(this.ccn);
        }
    }
    isCVCValid() {
        switch (this.cardtype) {
            case "AE":
                return this.cvc.length == 4 && this.isNumerical(this.cvc);
            case "OT":
                return this.cvc.length >= 3 && this.cvc.length <= 4 && this.isNumerical(this.cvc);
            default:
                return this.cvc.length == 3 && this.isNumerical(this.cvc);
        }
    }

    triggerDummyXelig() {
        this.paymentService.submitXelig(this.candidateid, this.examcode, true).then(result => {
            console.log("prometric response: %o", result);
        });
    }
    triggerStorageTest() {
        let cookieObj = document.cookie.split('; ').reduce((prev, current) => {
            const [name, ...value] = current.split('=');
            prev[name] = value.join('=');
            return prev;
        }, {});
        console.log("cookie: %o", cookieObj);
    }
    triggerLinksTest() {
        this.paymentService.getPrometricScheduleLink(this.candidateid, this.examcode).then(linkResult => {
            this.remoteLink = linkResult.replace("%%TEMPLATEREPLACE%%", "proctorsch");
            this.inPersonLink = linkResult.replace("%%TEMPLATEREPLACE%%", "schd");
            this.checkoutInProgress = false;
            this.paymentComplete = true; //hide page and show thank-you
            let emailCopy = "insert email copy here. links are " + this.remoteLink + " and " + this.inPersonLink + " .";
            //this.paymentService.sendConfirmationEmail(this.candidateid, emailCopy);
        })
    }
    getCVCMaxLength() {
        //American Express has 4 digits; other credit cards have 3 digits
        return (this.cardtype == "AE" || this.cardtype == "OT") ? 4 : 3;
    }
}